<template>
    <div class="flex flex-col items-center pt-20">
            <h3 class="userdata text-lg font-bold">{{ user?.tmpBusiness }}</h3>
            <p class="userdata text-base">{{ user ? user.email : email }}</p>
            <div class="w-24 my-8"><ElementsIcon icon="envelope" size="fullwidth" animate="bounce" /></div>
            <h2 class="text-lg font-bold mb-4 text-center">E-Mail bestätigen.</h2>
            <p class="text-center text-sm">Sie haben eine E-Mail von uns erhalten. Bitte klicken Sie auf den Bestätigungslink in der E-Mail, um das Konto zu aktivieren.</p>
            <ElementsButton class="mt-6" :loading="state.loading" :icon="state.icon" @click="resendConfirmationMail">E-Mail erneut senden</ElementsButton>
    </div>

</template>
<script setup>
const props = defineProps({
    email: { type: String, default: '' },
})
const user = useStrapiUser()
const { sendEmailConfirmation } = useStrapiAuth()

const state = reactive({
    loading: false,
    icon: 'envelope'
})

const resendConfirmationMail = async () => {
    state.loading = true
    try {
        await sendEmailConfirmation({ email: user.value ? user.value.email : props.email })
        state.icon = 'checkmark'
    } catch(e) {
        state.icon = 'cancel'
    }
    state.loading = false
}

</script>